export const LOCALE_MAP: { [key: string]: Record<string, string> } = {
  'en-US': {
    currency: 'USD',
    rlmLocale: 'en-us',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'MM/DD/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'MM-DD-YYYY',
    dateWithMonthNoAndShortYear: 'MM/DD/YY',
    dateWithMonthNoAndYear: 'MM/DD/YYYY',
    dateWithDayAndMonth: 'ddd, MMM DD',
    longDateWithDayMonthAndYear: 'dddd, MMMM DD, YYYY',
    dateWithDayMonthAndYear: 'ddd, MMM DD, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, MMM DD, YYYY - hh:mm A',
    dateWithMonthAndYear: 'MMM DD, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'es-ES': {
    currency: 'USD',
    rlmLocale: 'es',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'MM/DD/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'MM-DD-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YYYY',
    dateWithDayAndMonth: 'ddd, DD MMM',
    longDateWithDayMonthAndYear: 'dddd, DD MMMM, YYYY',
    dateWithDayMonthAndYear: 'ddd, DD MMM, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD MMM, YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD MMM, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'en-GB': {
    currency: 'EUR',
    rlmLocale: 'en-gb',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YYYY',
    dateWithDayAndMonth: 'ddd, DD MMM',
    longDateWithDayMonthAndYear: 'dddd, DD MMMM, YYYY',
    dateWithDayMonthAndYear: 'ddd, DD MMM, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD MMM, YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD MMM, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'pt-BR': {
    currency: 'USD',
    rlmLocale: 'pt-br',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YYYY',
    dateWithDayAndMonth: 'ddd, MMM DD',
    longDateWithDayMonthAndYear: 'dddd, MMMM DD, YYYY',
    dateWithDayMonthAndYear: 'ddd, MMM DD, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, MMM DD, YYYY - hh:mm A',
    dateWithMonthAndYear: 'MMM DD, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'ko-KR': {
    currency: 'USD',
    rlmLocale: 'ko',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'YY/MM/DD',
    dateWithMonthNoAndYear: 'YYYY.MM.DD',
    dateWithDayAndMonth: 'MMM DD[일], ddd',
    longDateWithDayMonthAndYear: 'YYYY[년] MMMM DD[일], dddd',
    dateWithDayMonthAndYear: 'YYYY[년] MMM DD[일], ddd',
    dateWithDayMonthYearAndTime: 'YYYY[년] MMM DD[일], ddd - hh:mm A',
    dateWithMonthAndYear: 'YYYY[년] MMM DD[일]',
    monthAndYear: 'YYYY[년] MMM',
    hourAndMinute: 'hh:mm A',
    year: '년',
  },
  'ar-AE': {
    currency: 'USD',
    rlmLocale: 'ar',
    firstDayofWeek: '6',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YY',
    dateWithDayAndMonth: 'MMM DD, ddd',
    longDateWithDayMonthAndYear: 'YYYY, MMMM DD, dddd',
    dateWithDayMonthAndYear: 'YYYY, MMM DD, ddd',
    dateWithDayMonthYearAndTime: 'YYYY, MMM DD, ddd - hh:mm A',
    dateWithMonthAndYear: 'YYYY, MMM DD',
    monthAndYear: 'YYYY MMM',
    hourAndMinute: 'hh:mm A',
  },
  'en-AU': {
    currency: 'AUD',
    rlmLocale: 'en-au',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YY',
    dateWithDayAndMonth: 'ddd, MMM DD',
    longDateWithDayMonthAndYear: 'dddd, MMMM DD, YYYY',
    dateWithDayMonthAndYear: 'ddd, MMM DD, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, MMM DD, YYYY - hh:mm A',
    dateWithMonthAndYear: 'MMM DD, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'fr-FR': {
    currency: 'EUR',
    rlmLocale: 'fr',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YYYY',
    dateWithDayAndMonth: 'ddd, DD MMM',
    longDateWithDayMonthAndYear: 'dddd, DD MMMM, YYYY',
    dateWithDayMonthAndYear: 'ddd, DD MMM, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD MMM, YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD MMM YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'de-DE': {
    currency: 'EUR',
    rlmLocale: 'de',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD.MM.YYYY',
    dateWithDayAndMonth: 'ddd, DD[.] MMM',
    longDateWithDayMonthAndYear: 'dddd, DD[.] MMMM YYYY',
    dateWithDayMonthAndYear: 'ddd, DD[.] MMM YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD[.] MMM YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD[.] MMM YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'it-IT': {
    currency: 'EUR',
    rlmLocale: 'it',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD/MM/YYYY',
    dateWithDayAndMonth: 'ddd, DD MMM',
    longDateWithDayMonthAndYear: 'dddd, DD MMMM, YYYY',
    dateWithDayMonthAndYear: 'ddd, DD MMM, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD MMM, YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD MMM, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'ja-JP': {
    currency: 'JPY',
    rlmLocale: 'ja',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'MM/DD/YY',
    dateWithMonthNoAndYear: 'YYYY/MM/DD',
    dateWithDayAndMonth: 'MMM DD[日] (ddd)',
    longDateWithDayMonthAndYear: 'MMMM DD[日] (dddd) YYYY',
    dateWithDayMonthAndYear: 'MMM DD[日] (ddd) YYYY',
    dateWithDayMonthYearAndTime: 'MMM DD[日] (ddd) YYYY - hh:mm A',
    dateWithMonthAndYear: 'MMM DD[日] YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
    year: '年',
  },
  'ru-RU': {
    currency: 'RUB',
    rlmLocale: 'ru',
    firstDayofWeek: '1',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'DD/MM/YY',
    dateWithMonthNoAndYear: 'DD.MM.YYYY',
    dateWithDayAndMonth: 'ddd, DD MMM',
    longDateWithDayMonthAndYear: 'dddd, DD MMMM, YYYY',
    dateWithDayMonthAndYear: 'ddd, DD MMM, YYYY',
    dateWithDayMonthYearAndTime: 'ddd, DD MMM, YYYY - hh:mm A',
    dateWithMonthAndYear: 'DD MMM, YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
  'zh-CN': {
    currency: 'CNY',
    rlmLocale: 'zh-cn',
    firstDayofWeek: '0',
    slashedDateWithMonthNoAndYear: 'DD/MM/YYYY',
    hyphenatedDateWithMonthNoAndYear: 'YYYY-MM-DD',
    hyphenatedDateWithMonthNoAndYear2: 'DD-MM-YYYY',
    dateWithMonthNoAndShortYear: 'YY/MM/DD',
    dateWithMonthNoAndYear: 'YYYY-MM-DD',
    dateWithDayAndMonth: 'MMM DD[日], ddd',
    longDateWithDayMonthAndYear: 'MMMM DD[日], dddd, YYYY',
    dateWithDayMonthAndYear: 'MMM DD[日], ddd, YYYY',
    dateWithDayMonthYearAndTime: 'MMM DD[日], ddd, YYYY - hh:mm A',
    dateWithMonthAndYear: 'MMM DD[日], YYYY',
    monthAndYear: 'MMM YYYY',
    hourAndMinute: 'hh:mm A',
  },
};
