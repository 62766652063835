import moment from 'moment';
import {
  getDurationInDays,
  getDayOfTheWeek,
  getFormattedDate,
  getFormattedDateString,
  checkWeekdayWeekendStay,
} from './date';
import { SearchQueryOptions } from './search';
import { SEARCH_RESULTS_PAGE_SIZE, TRACK_COUNTER } from '../constants';
import { SessionManager } from './sessionManager';
import { showBookNow } from './searchResults';
import { GroupsRateProperty, PropertyAddress, PropertyBasicInformation } from '@marriott/mi-groups-graphql';
import { RfpSessionInput } from '../organisms/RfpTray/RfpTray.types';
import { QuickGroupData } from '../organisms/SearchResults/SearchResults.types';
import { EventProfileFormData } from '../organisms/EventProfile/EventProfile.types';

export const trackCounter = new SessionManager<number>(TRACK_COUNTER);

export const updateTrackCounter = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const currentCount = trackCounter.getItem<number>() || 0;
  window.history.replaceState(window.history.state, '', decodeURIComponent(`?${urlParams.toString()}#${currentCount}`));
  const nextCount = +currentCount + 1;
  trackCounter.setItem(nextCount);
};

export const getRoomsAndSpaceDetails = (numberOfRooms?: string, numberOfAttendees?: string) =>
  +(numberOfRooms || 0) && +(numberOfAttendees || 0)
    ? 'roomsAndSpace'
    : +(numberOfRooms || 0)
    ? 'roomsOnly'
    : 'spaceOnly';

export const updateDataLayerWithSearchAttributes = (
  queryOptions: SearchQueryOptions,
  properties: GroupsRateProperty[],
  isQuickGroupEnabled = false,
  error = '',
  locale = ''
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentPage = searchParams.get('page') || 1;
  const viewType = searchParams.get('view') || 'List';
  const [city = '', state = '', country = ''] = queryOptions.destination?.split(', ') || ['', '', ''];
  const quickGroupEnabledProperties = properties
    .filter(
      property =>
        property.groupRate &&
        showBookNow(
          property.groupRate,
          +(queryOptions.guestRooms || 0),
          +(queryOptions.attendees || 0),
          locale,
          property.node.basicInformation.brand.id
        )
    )
    .map(propery => propery.node.id);

  const propertyIds = properties?.map(property =>
    quickGroupEnabledProperties.includes(property.node.id) ? `${property?.node.id}|QG` : `${property?.node.id}|RFP`
  );

  const updatedDataLayer = {
    search_list_records_total: properties?.length || 0,
    group_qg_rfp_serp_summary: `Default Page ${currentPage} Group Search Results:${
      quickGroupEnabledProperties.length
    } of ${SEARCH_RESULTS_PAGE_SIZE} QuickGroup Enabled:${
      SEARCH_RESULTS_PAGE_SIZE - quickGroupEnabledProperties.length
    } of ${SEARCH_RESULTS_PAGE_SIZE} RFP Only`,
    group_sgo_search: isQuickGroupEnabled,
    search_google_places_destination: `Name:${city}|Locality:${city}|State:${state}|Postal Code:|Country:${country}|Latitude:${queryOptions?.latitude}|Longitude:${queryOptions?.longitude}|Types:|Address:|Website:`,
    search_destination_country: `${country}`,
    search_destination_city: `${city}`,
    search_destination_state: `${state}`,
    search_destination_city_state_country: `${city}:${state}:${country}`,
    search_date_type: queryOptions?.startDate && queryOptions?.endDate ? 'With Dates' : 'Without Dates',
    search_date_check_in: queryOptions?.startDate
      ? getFormattedDateString(queryOptions?.startDate, 'slashedDateWithMonthNoAndYear')
      : '',
    search_date_check_out: queryOptions?.endDate
      ? getFormattedDateString(queryOptions?.endDate, 'slashedDateWithMonthNoAndYear')
      : '',
    search_advance_purchase_days: getDurationInDays(
      getFormattedDate(moment(new Date()), 'hyphenatedDateWithMonthNoAndYear2'),
      queryOptions?.startDate
    ),
    search_date_check_in_day_of_week: getDayOfTheWeek(queryOptions?.startDate),
    res_stay_length: getDurationInDays(queryOptions?.startDate, queryOptions?.endDate),
    search_is_weekend_stay: checkWeekdayWeekendStay(queryOptions?.startDate, queryOptions?.endDate),
    group_event_type: queryOptions?.eventType || '',
    group_room_attendee_count: `Rooms=${queryOptions?.guestRooms || 0};Event=${
      queryOptions?.attendees || 0
    } # of attendees`,
    group_search_options: `selectedEventOption=${getRoomsAndSpaceDetails(
      queryOptions?.guestRooms,
      queryOptions?.attendees
    )}`,
    res_rooms_per_night: `${queryOptions?.guestRooms}`,
    group_search_results_properties: propertyIds,
    search_results_view: `${viewType} View`,
    group_error_type: error,
    page_data_layer_ready: true,
  };

  Object.assign(window?.dataLayer || {}, updatedDataLayer);
};

export const updateViewType = (viewType = 'List') => {
  Object.assign(window?.dataLayer || {}, { search_results_view: `${viewType} View` });
};

export const updateDataLayerWithRoomingListAttributes = (
  marshaCode = '',
  brand?: { id: string; name: string },
  address?: PropertyAddress
) => {
  const currentCount = trackCounter.getItem<number>() || 0;

  const updatedDataLayer = {
    mrshaCode: marshaCode,
    prop_marsha_code: marshaCode,
    prop_brand_code: brand?.id || '',
    prop_brand_name: brand?.name || '',
    prop_address_city_state_country: address
      ? `${address.city || ''}|${address?.stateProvince?.code || ''}|${address?.country?.description || ''}`
      : '',
    updates_search_call_counter: currentCount,
    planner_submitted: false,
    data_layer_obj_ready: true,
  };

  Object.assign(window?.dataLayer || {}, updatedDataLayer);
  updatePageCount();
};

export const updateRoomingListPlannerSubmission = (isPlannerSubmitted: boolean) => {
  Object.assign(window?.dataLayer || {}, { planner_submitted: isPlannerSubmitted, page_data_layer_ready: true });
  updatePageCount();
};

export const updateDataLayerWithRFPAttributes = (
  sessionData?: RfpSessionInput,
  eventProfileData?: EventProfileFormData,
  rfpId?: string
) => {
  if (sessionData) {
    const { AriesGroupSearch } = sessionData;
    const groupSearchCriteria = AriesGroupSearch?.groupSearchCriteria;
    const startDate = eventProfileData?.startDate || groupSearchCriteria.checkInDate;
    const endDate = eventProfileData?.endDate || groupSearchCriteria.checkOutDate;
    const eventType = eventProfileData?.eventType.label || groupSearchCriteria.eventSearchType;
    const guestRooms = eventProfileData?.guestRooms || groupSearchCriteria.guestRoomCount;
    const attendees = eventProfileData?.attendees || groupSearchCriteria.sizeLargestMeetingRoom;

    const updatedDataLayer = {
      search_destination_city: groupSearchCriteria.address.city || '',
      search_destination_state: groupSearchCriteria.address.stateProvince || '',
      search_destination_country: groupSearchCriteria.address.country || '',
      search_date_check_in: startDate || '',
      search_date_check_out: endDate || '',
      search_date_check_in_day_of_week: getDayOfTheWeek(startDate),
      search_is_weekend_stay: checkWeekdayWeekendStay(startDate, endDate),
      search_advance_purchase_days: getDurationInDays(
        getFormattedDate(moment(new Date()), 'hyphenatedDateWithMonthNoAndYear2'),
        startDate
      ),
      res_stay_length: getDurationInDays(startDate, endDate),
      group_event_type: eventType || '',
      res_rooms_per_night: `${guestRooms || 0}`,
      group_room_attendee_count: `Rooms=${guestRooms || 0};Event=${attendees || 0}`,
      group_marsha_list: Object.keys(AriesGroupSearch?.rfpShortlistedMarshaCodes).toString(),
      group_sgo_search: false,
      data_layer_obj_ready: true,
    };

    if (rfpId) {
      const confirmationAttributes = {
        group_opportunity_id: rfpId,
        prop_marsha_code: AriesGroupSearch?.rfpShortlistedMarshaCodes,
        group_search_options: `selectedEventOption=${getRoomsAndSpaceDetails(
          `${guestRooms || 0}`,
          `${attendees || 0}`
        )}`,
      };

      Object.assign(updatedDataLayer, confirmationAttributes);
    }

    Object.assign(window?.dataLayer || {}, updatedDataLayer);
    updateTrackCounter();
    updatePageCount();
  }
};

export const updateDataLayerWithQuickGroupAttributes = (
  quickGroupData: Partial<QuickGroupData>,
  basicInformation: PropertyBasicInformation,
  address: PropertyAddress,
  contractType?: string,
  confirmationId?: string
) => {
  const updatedDataLayer = {
    prop_marsha_code: quickGroupData.propertyId,
    mrshaCode: quickGroupData.propertyId,
    prop_name: basicInformation?.name || '',
    prop_brand_code: basicInformation?.brand?.id || '',
    brndCode: basicInformation?.brand?.id || '',
    prop_brand_name: basicInformation?.brand?.name || '',
    prop_address_lat_long: `${basicInformation?.latitude || 0},${basicInformation?.longitude || 0}`,
    prop_address_city: address?.city || '',
    prop_address_state_abbr: address?.stateProvince?.code || '',
    prop_address_state_name: address?.stateProvince?.description || '',
    prop_address_country_abbr: address?.country?.description || '',
    prop_address_city_state_country: `${address?.city || ''}|${address?.stateProvince?.description || ''}|${
      address?.country?.description || ''
    }`,
    hotelLoc: `${address?.city || ''},${address?.stateProvince?.code || ''}, ${address?.country?.description || ''}`,
    res_stay_length: getDurationInDays(quickGroupData.startDate, quickGroupData.endDate),
    search_advance_purchase_days: getDurationInDays(
      getFormattedDate(moment(new Date()), 'hyphenatedDateWithMonthNoAndYear2'),
      quickGroupData.startDate
    ),
    search_date_check_in: quickGroupData.startDate,
    search_date_check_in_day_of_week: getDayOfTheWeek(quickGroupData.startDate),
    search_date_check_out: quickGroupData.endDate,
    search_day_check_in: getDayOfTheWeek(quickGroupData.startDate, 'ddd'),
    search_day_check_out: getDayOfTheWeek(quickGroupData.endDate, 'ddd'),
    search_is_weekend_stay: checkWeekdayWeekendStay(quickGroupData.startDate, quickGroupData.endDate),
    group_event_type: quickGroupData.eventType || '',
    group_room_attendee_count: `Rooms=${quickGroupData.numberOfRooms || 0};Event=${
      quickGroupData.numberOfAttendees || 0
    }`,
    res_rooms_per_night: `${quickGroupData.numberOfRooms || 0}`,
    group_search_options: `selectedEventOption=${getRoomsAndSpaceDetails(
      `${quickGroupData.numberOfRooms}`,
      `${quickGroupData.numberOfAttendees}`
    )}`,
    prop_currency_type: basicInformation?.currency || 'USD',
    prop_rewards_category_level: basicInformation.category || '',
    prop_earn_rewards: 'true', // TODO: loyalty info to be checked
    prop_rewards_earn_points_participant: 'true', // TODO: loyalty info to be checked
    page_data_layer_ready: true,
    data_layer_obj_ready: true,
  };

  if (contractType) {
    Object.assign(updatedDataLayer, { group_contract_type: contractType });
  }
  if (confirmationId) {
    Object.assign(updatedDataLayer, { group_opportunity_id: confirmationId });
  }

  Object.assign(window?.dataLayer || {}, updatedDataLayer);
  updatePageCount();
};

export const updatePageCount = () => {
  if (window?.dataLayer?.['data_layer_obj_ready']) {
    const currentCount = trackCounter.getItem<number>() || 0;
    Object.assign(window?.dataLayer || {}, { page_ready_counter: currentCount, data_layer_obj_ready: false });
    trackCounter.setItem(currentCount + 1);
  }
};
