import { logger } from '../lib/logger';
import { canUseDOM } from '../lib/helpers';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    adobeDataLayer: {
      [key: number]: {
        event: string;
        data: Record<string, unknown>;
      };
      version: number;
      push: (event: { event: string; data: Record<string, unknown> }) => void;
      getState: () => Record<string, unknown>;
      addEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
      removeEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
    };
  }
}

type UseDataLayer = {
  pushState: (eventData: DataLayerEvent) => void;
  getState: () => Record<string, unknown> | null;
  datalayer: Record<string, unknown>;
};

type DataLayerEvent = {
  event: string;
  data: Record<string, unknown>;
};

export function useDatalayer() {
  const { log } = logger({})('useDatalayer');
  const envVarsObject = useClientEnvVarsStore(state => state.envVarsObject);
  const [datalayer, setDatalayer] = useState<Record<string, unknown>>();

  function handleLoadDatalayer() {
    const isEnableWebSDK = envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK'];
    if (!isEmpty(datalayer) || !isEnableWebSDK) {
      return;
    }
    const data = window.adobeDataLayer?.getState()?.['data'] as Record<string, unknown>;
    if (!isEmpty(data)) {
      setDatalayer(data);
      log.info('loading the adobeDataLayer current state', data);
    }
  }

  const adobeDataLayer = {
    pushState: (payload: DataLayerEvent) => {
      if (canUseDOM) {
        log.info('pushing to adobeDataLayer', payload);
        window.adobeDataLayer?.push(payload);
      }
    },
    getState: () => {
      if (canUseDOM) {
        return window.adobeDataLayer?.getState();
      }
      return null;
    },
  };

  useEffect(() => {
    window.addEventListener('loadDataLayer', handleLoadDatalayer);
    // for backward compatibility with old datalayer. this will be removed once all the pages are migrated to the new datalayer
    const isEnableWebSDK = envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK'];
    if (!isEnableWebSDK) {
      const oldDataLayer = window.dataLayer;
      if (!isEmpty(oldDataLayer)) {
        setDatalayer(oldDataLayer);
        log.info('loading the old datalayer from window', oldDataLayer);
      }
    }
    return () => window.removeEventListener('loadDataLayer', handleLoadDatalayer);
  }, []);

  return { ...adobeDataLayer, datalayer } as UseDataLayer;
}
