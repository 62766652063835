import { PropertyFacet, SittingArrangement } from '@marriott/mi-groups-graphql';

export * from './dateConstants';

export const NEXT_PUBLIC_ENV_KEYS = [
  'NEXT_PUBLIC_AEM_CF_HOST',
  'NEXT_PUBLIC_ENCRYPTION_KEY',
  'SESSION_APP_GET_CALL_URL',
  'SESSION_APP_POST_CALL_URL',
];

export const ASSET_DOMAIN = 'https://cache.marriott.com';
export const MARRIOTT_DOMAIN = 'https://www.marriott.com';
export const RITZ_CARLTON_DOMAIN = 'https://www.ritzcarlton.com';

export const USER_SESSION_TIMEOUT = 30 * 60 * 1000;

export const TRACK_COUNTER = 'trackCounter';

export const DEFAULT_LANG = 'en-US';
export const CHINESE_LANG = 'zh-CN';
export const SPANISH_LANG = 'es-ES';
export const CHINESE_DOMAIN_SUFFIX = '.cn';
export const DEFAULT_CALLING_CODE = '+1'; // USA
export const USA = 'US';
export const CANADA = 'CA';

export const NUM_REGEX = /^\d+$/;
export const NUM_UPTO_FIVE_REGEX = /^\d{0,5}$/;
export const NUM_SIX_REGEX = /^\d{6}$/;
export const LETTERS_REGEX = /^[A-Za-z]+$/;
export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]*$/;
export const CITY_REGEX = /^[A-Za-z\s-]+$/;
export const STATE_CODE_REGEX = /^[a-zA-Z]{2}$/;
export const GENERAL_ZIP_CODE_REGEX = /^[a-zA-Z0-9\-,:?()[\]{}#$%^&;"]*$/;
export const USA_ZIP_CODE_REGEX = /^\d{5}(?:-\d{4})?$/;
export const CANADA_ZIP_CODE_REGEX = /[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/;
export const MARSHA_CODE_REGEX = /^[A-Za-z]{5}(?:\/[A-Za-z]{5})?$/;
export const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const MULTIPLE_EMAIL_REGEX =
  /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
export const OPTIONAL_EMAIL_REGEX = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const USER_NAME_REGEX = /^[A-Za-z\s'-]+$/;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s/#.-]*$/;
export const GSO_NAME_REGEX = /^[A-Za-z ]*$/;

export const ENTER_KEY = 'Enter';
export const TAB_KEY = 'Tab';
export const BACKSPACE_KEY = 'Backspace';

export const RECENT_SEARCH_CACHE_KEY = 'miRecentSearch';
export const RECENT_LIST_LENGTH = 5;
export const DESTINATION_FIELD_ID = 'destinationField';
export const TYPE_AHEAD_DELAY = 500;
export const MINIMUM_GUEST_ROOMS_COUNT = 10;

export const CALENDAR_MAX_ALLOWED_DAYS = 3 * 365;
export const CALENDAR_VARIATION_DESKTOP = 'DESKTOP';
export const CALENDAR_VARIATION_VERTICAL = 'VERTICAL';
export const CALENDAR_START_DATE = 'startDate';
export const CALENDAR_END_DATE = 'endDate';
export const CALENDAR_SHORT_WEEKDAY_INITIALS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const CALENDAR_SHORT_WEEKDAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const CALENDAR_LONG_WEEKDAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const CALENDAR_SHORT_MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const CALENDAR_TIME_FORMAT = 'hh:mm A';

export const SEARCH_CRITERIA = [
  'destination',
  'latitude',
  'longitude',
  'startDate',
  'endDate',
  'flexibleDates',
  'eventType',
  'guestRooms',
  'attendees',
  'placeId',
  'states',
  'countries',
];

export const QUERY_PARAMS_TO_FACET_KEYS: Record<string, PropertyFacet> = {
  // term facets
  activities: PropertyFacet.ACTIVITIES,
  amenities: PropertyFacet.AMENITIES,
  cities: PropertyFacet.CITIES,
  states: PropertyFacet.STATES,
  countries: PropertyFacet.COUNTRIES,
  transportation: PropertyFacet.TRANSPORTATION_TYPES,
  marriottBrands: PropertyFacet.BRANDS,
  events: PropertyFacet.MEETINGS_EVENTS,
  // range facets
  distance: PropertyFacet.DISTANCE,
};
export const ARIES_SEARCH_VO_FACET_KEYS_MAP: Record<string, PropertyFacet> = {
  'searchCriteriaVO.stateProvince': PropertyFacet.STATES,
  'searchCriteriaVO.country': PropertyFacet.COUNTRIES,
};
export const ARIES_SEARCH_DA_FACET_KEYS_MAP: Record<string, PropertyFacet> = {
  'destinationAddress.stateProvince': PropertyFacet.STATES,
  'destinationAddress.country': PropertyFacet.COUNTRIES,
};

export const FACET_KEYS_TO_QUERY_PARAMS: Partial<Record<PropertyFacet, string>> = Object.fromEntries(
  Object.entries(QUERY_PARAMS_TO_FACET_KEYS).map(([paramName, facetKey]) => [facetKey, paramName])
);

// Aries Query Params Name
const QUERY_PARAMS_ARIES_SEARCH_COMMON_MAP = {
  startDate: 'fromDate',
  endDate: 'toDate',
  flexibleDates: 'flexibleDateSearch',
  eventType: 'eventType',
  attendees: 'searchCriteriaVO.sizeLargestMeetingRoom',
  placeId: 'placeId',
};
export const QUERY_PARAMS_ARIES_SEARCH_VO_MAP = {
  ...QUERY_PARAMS_ARIES_SEARCH_COMMON_MAP,
  destination: 'searchCriteriaVO.destination',
  latitude: 'searchCriteriaVO.latitude',
  longitude: 'searchCriteriaVO.longitude',
  guestRooms: 'searchCriteriaVO.guestRoomCount',
  city: 'searchCriteriaVO.city',
  states: 'searchCriteriaVO.stateProvince',
  countries: 'searchCriteriaVO.country',
};
export const QUERY_PARAMS_ARIES_SEARCH_DA_MAP = {
  ...QUERY_PARAMS_ARIES_SEARCH_COMMON_MAP,
  destination: 'destinationAddress.destination',
  latitude: 'destinationAddress.latitude',
  longitude: 'destinationAddress.longitude',
  guestRooms: 'roomCount',
  city: 'destinationAddress.city',
  states: 'destinationAddress.stateProvince',
  countries: 'destinationAddress.country',
};
export const QUERY_PARAM_DESTINATION = 'destination';
export const QUERY_PARAM_ARIES_SEARCH_VO_DESTINATION = QUERY_PARAMS_ARIES_SEARCH_VO_MAP[QUERY_PARAM_DESTINATION];
export const QUERY_PARAM_ARIES_SEARCH_DA_DESTINATION = QUERY_PARAMS_ARIES_SEARCH_DA_MAP[QUERY_PARAM_DESTINATION];

export const SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS = ['0', '5000', '15000', '80000'];
export const SEARCH_RESULTS_PAGE_SIZE = 20;

export const PROPERTY_IMAGE_OUTPUT_QUALITY = 90;
export const PROPERTY_IMAGE_CROPPING_RATIO = 6;
export const PROPERTY_IMAGE_OUTPUT_INTERPOLATION = 'progressive-bilinear';
export const PROPERTY_IMAGE_RENDITION_WIDE = 'wide';
export const PROPERTY_IMAGE_RENDITION_CLASSIC = 'classic';
export const PROPERTY_IMAGE_RENDITION_SQUARE = 'square';
export const PROPERTY_IMAGE_LIST_CATEGORY_LIMIT = 2;
export const PROPERTY_IMAGE_CAROUSEL_LENGTH = 10;
export const PROPERTY_IMAGE_DIMENSIONS = {
  MINI_IMAGE_WIDTH: 120,
  MINI_IMAGE_HEIGHT: 120,
  REGULAR_MOBILE_IMAGE_WIDTH: 333,
  REGULAR_MOBILE_IMAGE_HEIGHT: 222,
  REGULAR_TABLET_IMAGE_WIDTH: 360,
  REGULAR_TABLET_IMAGE_HEIGHT: 240,
  REGULAR_DESKTOP_IMAGE_WIDTH: 512,
  REGULAR_DESKTOP_IMAGE_HEIGHT: 288,
  LARGE_DESKTOP_IMAGE_WIDTH: 665,
  LARGE_DESKTOP_IMAGE_HEIGHT: 288,
};
export const PROPERTY_IMAGE_RENDITION = {
  [PROPERTY_IMAGE_RENDITION_WIDE]: 'wideHorizontal',
  [PROPERTY_IMAGE_RENDITION_CLASSIC]: 'classicHorizontal',
  [PROPERTY_IMAGE_RENDITION_SQUARE]: 'square',
};

export const PROPERTY_DEFAULT_IMAGE_URL = {
  classic: `${ASSET_DOMAIN}/is/image/marriotts7prod/bonvoy-error-image-10518:Classic-Hor`,
  square: `${ASSET_DOMAIN}/is/image/marriotts7prod/bonvoy-error-image-10518:Square`,
  wide: `${ASSET_DOMAIN}/is/image/marriotts7prod/bonvoy-error-image-10518:Wide-Hor`,
  altText: 'Fallback',
};

export const MILES = 'mi';
export const KILOMETERS = 'km';
export const KILOMETERS_CN = '公里';
export const SQFEET = 'sq.ft';
export const SQMETERS = 'sq.m.';
export const SQMETERS_CN = '平米';
export const MILES_TO_METERS = 1609.344;
export const MILES_TO_KILOMETERS = MILES_TO_METERS / 1000;
export const KILOMETERS_TO_MILES = 0.621371;
export const FEET_TO_METERS = 0.3048;
export const SQFEET_TO_SQMETERS = 0.092903;
export const SQMETERS_TO_SQFEET = 10.7639;

export const CATERING_CODE = 'CAT';
export const HIGH_SPEED_INTERNET_ACCESS_CODE = 'NET.4';

export const GOOGLE_MAP_SEARCH_URL = 'https://www.google.com/maps/search/';

export const MAP_VIEW_HEIGHT_OFFSET = 66;

export const HWS_BRANDS = ['BCNRZ', 'DALIL', 'LISRZ'];
export const RITZ_CARLTON = 'RZ';
export const CITY_EXPRESS = 'XE';

export const RFP_EXCLUDED_BRANDS = ['DS'];
export const RFP_OPENING_SOON_DURATION = 180;

export const RFP_TRAY_MAX_SIZE = 3;
export const RFP_TRAY_HEIGHT = {
  MOBILE_EXPANDED: 430,
  TABLET_EXPANDED: 240,
  DESKTOP_EXPANDED: 300,
  TABLET_AND_ABOVE_COLLAPSED: 80,
  MOBILE_COLLAPSED: 70,
};

export const DEFAULT_CARD_NUMBER_LENGTH = 16;
export const AMEX_CARD_NUMBER_LENGTH = 15;
export const UNION_PAY_CARD_NUMBER_LENGTH = 20;
export const PAYMENT_METHOD_TYPE_CODE = '5.PMT';
export const CREDIT_CARD_EXCLUDED_CODES = ['MR'];
export const INVALID_CREDIT_CARD_NUMBER = 'credit_card_number_invalid';
export const PROCESS_CREDIT_CARD_URL = '/mi/phoenix-payment/v1/processCreditCard';

export const RESLINK_MAP_VIEW_HEIGHT_OFFSET = 2;
export const RESLINK_MAX_PROPERTY_COUNT = 10;
export const RESLINK_MAX_ROOM_COUNT = 10;

export const OTHER_EVENT_TYPE = 'Other';

export const RFP_UPLOAD_MAX_SIZE = 3 * 1024 * 1024; // 3MB
export const RFP_UPLOAD_MAX_FILES = 2;
export const RFP_UPLOAD_ALLOWED_FILE_TYPES = [
  '.pdf',
  '.jpg',
  '.txt',
  '.gif',
  '.tif',
  '.png',
  '.doc',
  '.xls',
  '.ppt',
  '.xlsx',
  '.tiff',
];

export const FILE_UPLOAD_URL = '/mi/v1/phoenix-file-upload-service/file-upload';
export const FILE_DELETE_URL = '/mi/phoenix-file-upload-service/v1/file-delete';

export const AVAILABILITY_SEARCH_URL = '/reservation/availabilitySearch.mi';

export const RFP_EVENT_SPACE_DEFAULTS = {
  START_TIME: '9:00 AM',
  MIN_START_TIME: '00:00 AM',
  END_TIME: '5:00 PM',
  MAX_END_TIME: '11:30 PM',
  SPACE: SittingArrangement.NA,
};

// Quick Group Constants
export const QG_ENABLED = 'true';
export const QG_EXCLUDED_CODES = ['0', '2', '4'];
export const QG_EXCLUDED_CODES_ONLY_ATTENDEES = ['0', '1', '2', '4'];
export const QG_ELIGIBLE_LOCALES = 'en-US,es-ES';
export const QG_MIN_GUEST_ROOMS = 10;
export const QG_MAX_GUEST_ROOMS = 25;
export const QG_MAX_ATTENDEES = 50;
export const QG_MIN_LENGTH_OF_STAY = 1;
export const QG_MAX_LENGTH_OF_STAY = 7;
export const QG_MIN_BOOKING_DAY_COUNT = 4;
export const QG_MAX_BOOKING_DAY_COUNT = 365;
export const QG_MIN_DURATION_FOR_INDIVIDUAL_RESERVATIONS = 20;

export enum QG_SEARCH_REQUEST_CODE {
  NUM_ROOMS_ONLY = 'NUM_ROOMS_ONLY',
  NUM_ATTENDEES_ONLY = 'NUM_ATTENDEES_ONLY',
  NUM_ROOMS_AND_ATTENDEES = 'NUM_ROOMS_AND_ATTENDEES',
}

export const QG_DATA_CACHE_KEY = 'miQuickGroup';

export const QG_ROOM_AMENITIES_DISPLAY_ORDER = [
  { code: '1', iconClass: 'icon-all-suites' },
  { code: '12', iconClass: 'icon-accessibility' },
  { code: '8', iconClass: 'icon-bed' },
  { code: '3', iconClass: 'icon-sheet-set' },
  { code: '4', iconClass: 'icon-robe' },
  { code: '6', iconClass: 'icon-dining' },
  { code: '7', iconClass: 'icon-kitchenette' },
  { code: '5', iconClass: 'icon-lounge-access' },
  { code: '11', iconClass: 'icon-events' },
  { code: '9', iconClass: 'icon-internet' },
];

export const QG_EVENT_SPACE_DEFAULTS = {
  START_TIME: '8:00 AM',
  MIN_START_TIME: '06:00 AM',
  END_TIME: '5:00 PM',
  MAX_END_TIME: '12:00 AM',
};

export const CF_LONG_LOCALES = ['en-us', 'pt-br', 'en-gb', 'zh-cn'];
export const LOCALE_REGION_MAP: Record<string, string> = {
  'en-us': 'na',
  'es-es': 'latam',
  'pt-br': 'latam',
  'en-gb': 'emea',
  'de-de': 'emea',
  'fr-fr': 'emea',
  'it-it': 'emea',
  'ru-ru': 'emea',
  'ja-jp': 'apec',
  'ko-kr': 'apec',
  'zh-cn': 'greater-china',
};

export const QG_CONTRACT_TYPE_CF_PATH = '/mi/cf-assets/mi-groups/{region}/{locale}/type-{contractType}.json';

export const QG_EXCLUDED_FEE_AND_TAX_CODES = ['2', '3', '8', '15', '16', '17', '22', '24'];
export const QG_PORTERAGE_FEE = 'Porterage';

export const QG_DECIMAL_POINT_VALUE = 2;

export const QG_GUEST_ROOM_AMOUNT_UNIT_CODE = 'per person';
export const QG_GUEST_ROOM_AMOUNT_UNIT_DESCRIPTION = 'per person cost';
export const QG_EVENT_SPACE_AMOUNT_UNIT_CODE = 'per Item cost of';
export const QG_EVENT_SPACE_AMOUNT_UNIT_DESCRIPTION = 'per Item';
export const QG_CANCELLATION_AMOUNT_UNIT_CODE = 'cancellation cost';
export const QG_CANCELLATION_AMOUNT_UNIT_DESCRIPTION = 'QG cancellation cost';
export const QG_ATTRITION_AMOUNT_UNIT_CODE = 'per night';
export const QG_ATTRITION_AMOUNT_UNIT_DESCRIPTION = 'per night attrition rate';

export const INLINE_ENROLLMENT_URL = '/mi/phoenix-account-auth/v1/enroll';
export const INLINE_ENROLLMENT_DUPLICATE_EMAIL = 'emailDuplicateError';

export const QG_VCALENDAR_FILE_NAME = 'quickgroup.vcs';
export const QG_GOOGLE_CALENDAR_EVENT_URL = 'https://calendar.google.com/calendar/r/eventedit';
export const QG_DEFAULT_CHECK_IN_TIME = '14:00';
export const QG_DEFAULT_CHECK_OUT_TIME = '11:00';
