export const constants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  ROUTING_CONFIG: 'routingConfig',
  EXPERIENCE_SEGMENT: ['NOT Logged In'],
  RTL_LANG_LIST: `:lang(he-IL), :lang(ar-AE)`,
};

export const IMAGE_URL_DOMAIN = 'https://cache.marriott.com';

export const defaultPageTitle = {
  outletDetailsTitle: 'Marriott Bonvoy',
};

export enum RnBFILTERS {
  cuisine = 'cuisine',
  brands = 'brandId',
  hour = 'hours',
  distance = 'distance',
  seating = 'seating',
  bonvoyMember = 'bonvoyMembers',
  venueType = 'venueType',
  dietaryOptions = 'dietaryOptions',
  experiences = 'experiences',
  city = 'city',
  stateProvinceDescription = 'stateProvinceDescription',
  countryDescription = 'countryDescription',
  country = 'country',
}

export enum OutletCuisines {
  other = 'Other',
  multicuisine = 'Multiple cuisines',
}

export const searchResultsPageUrl = '/dining/search-results.mi';
export const PageSize = 10;
export const StaticAriticleItemsPerView = 3;
export const StaticAriticleDescriptionLimit = 250;
export const outletPromotionCurrPage = 1;

export const TARGET_BLANK = '_blank';
export const DEFAULT_LANG = 'en_US';
export const MIN_PAGE_HEIGHT = '100vh';
export const GOOGLE_LOCATION = 'https://www.google.com/maps/search/?api=1&query=';
export const OFFER_QUERY_FACET_TYPE = 'OUTLET_TYPE';
export const OFFER_QUERY_FACET_DIMENSION = 'Dining';
export const OFFER_QUERY_TYPE = 'NON_STAY_BASED_ANCILLARY_PRODUCT_OFFER';
export const HEADER_HEIGHT_MOBILE = '52px';
export const HEADER_HEIGHT_DESKTOP = '110px';

export const TRACKING_CONSTANT = {
  INTERNAL_LINK: 'internal',
  EXTERNAL_LINK: 'external',
  OUTLET_DETAILS: 'Outlet Primary Details',
  RESERVE_BUTTON: 'R&B Reserve Button',
  OUTLET_ADDITIONAL_SECTION: 'Outlet Additional Section',
  OUTLET_WEBSITE_LINK: 'R&B Website Link',
  OUTLET_RESERVATION_LINK: 'R&B Hotel Reservation Link',
  OUTLET_LOCATION_MAP: 'R&B Location on Map Link',
  OUTLET_PHONE_CONTACT: 'R&B Phone Link',
  OUTLET_EMAIL_CONTACT: 'R&B Email Link',
  OUTLET_AUXILIARY_SECTION: 'Outlet Auxiliary Section',
  OUTLET_BONVOY_FIND_MORE_LINK: 'R&B Bonvoy Find More Link',
  OUTLET_RECOMMENDATION_SECTION: 'Outlet Recommendation',
  OUTLET_ORDER_DELIVERY_LINK: 'R&B Order Delivery Link',
  OUTLET_OFFER_TAB: 'Offers Tab',
  OUTLET_OFFER_TAB_OFFER_CARD: 'R&B Offers Card',
  OUTLET_GALLERY_TAB: 'Gallery Tab',
  OUTLET_GALLERY_TAB_IMAGE: 'R&B Gallery Image',
  OUTLET_MENU_TAB: 'Outlet Menu Tab',
  OUTLET_MENU_TAB_MENU_CARD: 'R&B Menu Card',
  EXPLORE_NEARBY: 'R&B Explore Nearby Link',
  MINI_CARD: 'Mini Card',
  HOME_PAGE_RECOMMENDATION_SECTION: 'Home Page Recommendation',
  HOME_PAGE_SEARCH_FORM: 'Home Search Form',
  SEARCH_RESULTS_SEARCH_FORM: 'Search Results Search Form',
  SEARCH_BUTTON: 'R&B Search Button',
  UPDATE_SEARCH_BUTTON: 'R&B Update Search',
  EDITORIAL_FEATURE: 'Editorial Feature',
  READ_MORE_BUTTON: 'R&B Read More',
  COLLAGE_EDITORIAL: 'Collage Editorial',
  LEARN_MORE_BUTTON: 'R&B Learn More',
  BONVOY_MEMBER_BANNER: 'Bonvoy Member Banner',
  JOIN_NOW_BUTTON: 'R&B Join Now',
  SIGN_IN_BUTTON: 'R&B Sign In',
  SEARCH_RESULTS_PAGINATION: 'Search Results Pagination',
  PREVIOUS_BUTTON: 'Previous',
  NEXT_BUTTON: 'Next',
  PAGE_BUTTON: 'Page Number',
  SEARCH_RESULT_PAGE: 'Search Result Page',
  ALL_FILTER_BUTTON: 'R&B All Filter Button',
  SEARCH_RESULT_CARD: 'Search Result Card',
  RESTAURANT_DETAILS_BUTTON: 'R&B Restaurant Details',
  SEARCH_FILTER_MODAL: 'R&B Search Filter Modal',
  SEARCH_FILTER_CLEAR: 'R&B Clear Button',
  SEARCH_FILTER_APPLY: 'R&B Apply Button',
  SEARCH_FILTER_CLOSE_ICON: 'R&B Close Icon',
  BONVOY_FILTER: 'Bonvoy Filter',
  BRAND_FILTER: 'Brand Filter',
  CUISINE_FILTER: 'Cuisine Filter',
  DISTANCE_FILTER: 'Distance Filter',
  HOURS_FILTER: 'Hours Filter',
  SEATING_FILTER: 'Seating Filter',
  FILTER_OPTION: 'Option',
  SELECTED_FILTER_PILL: 'Selected Filter Pill',
  VENUE_TYPE_FILTER: 'Venue Type Filter',
  DIETARY_OPTIONS_FILTER: 'Dietary Options Filter',
  EXPERIENCES_FILTER: 'Experiences Filter',
  CITY_FILTER: 'City Filter',
  STATE_PROVINCE_DESC_FILTER: 'stateProvinceDescription Filter',
  COUNTRY_DESC_FILTER: 'Country Filter',
  OFFER_SECTION: 'R&B OFFER SECTION',
  OFFERS_VIEW_DETAILS: 'R&B View Offer',
  LOCATION_FILTER_BUTTON: 'R&B Location Filter Button',
  CUISINE_FILTER_BUTTON: 'R&B Cuisine Filter Button',
  CITY_FEATURE_SECTION: 'City Page Feature Section',
  CITY_FEATURED_VIEWALL: 'R&B Feature Component View All Button',
  CITY_FEATURE_RESERVE_BUTTON: 'R&B Feature Card Reserve Button',
  CITY_FEATURE_RESTAURANT_DETAILS: 'R&B Feature Card Restaurants Button',
  CITY_ARTICLE_COMPONENT: 'City Page Article Cards Component',
  CITY_ARTICLE_CARD_BUTTON: 'R&B Article Card Button',
  CITY_ARTICLE_COLLAGE_COMPONENT: 'City Page Article Collage Component',
  CITY_ARTICLE_BUTTON: 'R&B Article Button',
  CITY_OFFERS_COMPONENT: 'City Page Offers',
  CITY_OFFERS_RNB: 'R&B Offers Card',
  CITY_PAGE: 'City Page',
  CITY_BONVOYBANNER: 'City Page Bonvoy Banner',
  CITY_BONVOYBANNER_LEARNMORE: ' R&B Sign Learn More Button',
  EARN_REDEEM_PAGE: 'Earn & Redeem Page',
  EARN_REDEEM_EARN_POINT_SECTION: 'Earn & Redeem Page Program Point Section',
  EARN_REDEEM_EARN_POINT_SECTION_CTA: 'R&B Earn Point CTA Button',
  EARN_REDEEM_EARN_POINT_SECTION_MEMBER_CTA: 'R&B Earn Point Member CTA Button',
  EARN_REDEEM_RECOMMENDATIONS: 'Earn & Redeem Page Recommendation Outlets',
  EARN_REDEEM_RECOMMENDATIONS_SEARCH_CTA: 'R&B Recommendation Search Button',
  EARN_REDEEM_RECOMMENDATIONS_RESERVE_CTA: 'R&B Recommendation Reserve Button',
  EARN_REDEEM_RECOMMENDATIONS_VIEWDETAILS_CTA: 'R&B Recommendation View Details Button',
  EARN_REDEEM_RECOMMENDATIONS_LOCATION_TRACKING_CTA: 'R&B Recommendation Location Track Button',
  EARN_REDEEM_CONDENSED_FEATURE: 'Earn & Redeem Page Condensed Feature Section',
  EARN_REDEEM_CONDENSED_RESERVE_CTA: 'R&B Condensed Reserve Button',
  EARN_REDEEM_CONDENSED_VIEWDETAILS_CTA: 'R&B Condensed View Details Button',
  CAMPAIGN_PAGE: 'Campaign Page',
  CAMPAIGN_PAGE_RESERVE_BUTTON: 'R&B Campaign Reserve Button',
  CAMPAIGN_PAGE_OVERLAY_LOCATION_BUTTON: 'R&B Campaign Location Link',
  CAMPAIGN_PAGE_OVERLAY_PHONE_BUTTON: 'R&B Campaign Phone Link',
  CAMPAIGN_PAGE_OVERLAY_VENUE_DETAILS: 'R&B Campaign Venue Details',
};

export const trackingDetailArr = [
  { text: 'trackingContentPosition', pre: '' },
  { text: 'trackingDescription', pre: 'msg=' },
  { text: 'trackingOfferType', pre: 'type=' },
  { text: 'trackingTag', pre: 'tag=' },
  { text: 'rnb_outlet_id', pre: 'rnb_outlet_id=' },
];

export const RECOMMENDED_RESULT_LIMIT = 9;

export const distanceFilterOptions = [
  {
    label: '0 - 50 miles',
    minimumDistance: '0',
    maximumDistance: '50',
  },
  {
    label: '0 - 1 miles',
    minimumDistance: '0',
    maximumDistance: '1',
  },
  {
    label: '1 - 2 miles',
    minimumDistance: '1',
    maximumDistance: '2',
  },
  {
    label: '2 - 10 miles',
    minimumDistance: '2',
    maximumDistance: '10',
  },
  {
    label: '10 - 25 miles',
    minimumDistance: '10',
    maximumDistance: '25',
  },
  {
    label: '25 - 50 miles',
    minimumDistance: '25',
    maximumDistance: '50',
  },
];

export enum backgroundColorMapping {
  black = '#1c1c1c',
  offwhite = '#f4f4f4',
}

export const OUTLET_HERO_FALLBACK_IMAGE = {
  xmobile: 'square?downsize=567:*',
  mobile: 'panoramicHorizontal?downsize=760:*',
  tablet: 'panoramicHorizontal?downsize=*:333',
  desktop: 'panoramicHorizontal?downsize=*:666',
};

export const SEARCH_RESULTS_FALLBACK_IMAGE = {
  mobile: 'wideHorizontal?downsize=332:*',
  tablet: 'wideHorizontal?downsize=332:*',
  desktop: 'wideHorizontal?downsize=504:*',
};

export const MaxCharCount = 350;
export const MaxCharCountMenu = 100;
export const chinaLocale = 'zh-cn';
export const defaultLocale = 'en-us';
export const outletprimaryType = 'restaurant';
export const outletsecondaryType = 'bar';

export const PAGES = {
  HOMEPAGE: 'home-page',
  SEARCH_RESULTS_PAGE: 'search-results-page',
  OUTLET_DETAILS_PAGE: 'outlet-details-page',
  CITYPAGE: 'city-page',
  CAMPAIGN_PAGE: 'campaign-page',
  EARN_REDEEM_PAGE: 'earn-redeem-page',
};

export const galleryImagesCountDesktop = 6;
export const galleryImagesCountMobile = 3;

export const menuCountDesktop = 4;
export const menuCountMobile = 2;
export const offerTitlemMxChar = 80;

export const menuCountDesktopOffer = 4;
export const menuCountMobileOffer = 2;

export const menuFooterListDesktop = 5;
export const menuFooterListMobile = 8;

export const packageBundle = 'packageBundle';
export const sectionBundle = 'sectionBundle';
export const sectionItem = 'sectionItem';
export const addonItem = 'addonItem';
export const itemBundle = 'itemBundle';
export const dietType = 'diet';
export const allergenType = 'allergen';

export const menuDescriptionLimitDesktop = 500;
export const sectionNameLimit = 50;
export const sectionDescriptionLimit = 200;
export const sectionNameMenuLimit = 100;
export const itemBundleNameLimit = 30;
export const footerDisclaimerLimit = 150;

export const MAX_OFFER_CARDS_HOMEPAGE = 9;

export const openTextSearchType = 'opentext';
export const geoSearchType = 'geosearch';
export const otsUrlTypeValue = 'ots';
export const otsUrlTypeTerm = 'type';
export const OTSPlaceHolderLimit = 45;

export const allFilters = 'allFilters';
export const locationFilter = 'locationFilter';
export const cuisineFilter = 'cuisineFilter';

export const oneStar = '1 Star';
export const twoStar = '2 Star';
export const threeStar = '3 Star';
export const bibGourmand = 'Bib Gourmand';
export const greenStar = 'Green Star';

export const outletNameLimit = 35;

export const listItem = 'ListItem';
export const itemList = 'ItemList';
export const richResultImageVariations = ['classicHorizontal', 'square'];

export enum tags {
  span = 'span',
  div = 'div',
  paragraph = 'p',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}
export interface renditions {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}
export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string | undefined;
  location?: string;
  payloadType?: string;
  compName?: string;
}

export const COUNTRYTYPE = 'COUNTRIES';
export const earnWithBonvoyPoints = 'Earn Marriott Bonvoy Points';
export const payWithBonvoyPoints = 'Pay with Marriott Bonvoy Points';
export const earnRedeemPageType = 'earnRedeemPage';

export interface CampaignParameters {
  attributionSource?: string;
  outletList: string;
  param1?: string;
  param1Value?: string;
  param2?: string;
  param2Value?: string;
  trackingProperties?: TrackingPropsInterface;
  wcmMode?: string;
  isAuthor?: boolean;
}

export const menuId = 'menu';
export const requestedMenu = 'Requested Menu';

export const offerType = {
  MbopOffer: 'MBOP',
  CHUBOffer: 'CHUB',
};
export const campaignOffersLimit = 9;

export const LOCALE_SUBDIRECTORY_MAPPING: Record<string, string> = {
  'en-US': '/en-us',
  'fr-FR': '/fr',
  'ar-AE': '/ar',
  'de-DE': '/de',
  'ja-JP': '/ja',
  'ko-KR': '/ko',
  'pt-BR': '/pt-br',
  'es-ES': '/es',
  'it-IT': '/it',
  'zh-CN': '/cn',
  'ru-RU': '/ru',
  'en-GB': '/en-gb',
  'zh-TW': '/zh-hk',
};

export const offerCard = 'offerCard';
export const offerOverlay = 'offerOverlay';

export const globalHomePageEurope = 'europe';
export const globalDefaultHomePage = 'global';
export const bonvoyMemberType = 'bonvoyMembers';
export const earnBonvoyPointsFilter = 'bonvoyPointsOffFolio';
export const payBonvoyPointsFilter = 'payWithBonvoyPoints';

export const outletPageUrl = '/dining/restaurant-bar/';
